a {text-decoration: none;}

.body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@keyframes hoverEffect {
  0% {filter: brightness(100%)}
  100% {filter: brightness(80%)}
}

#grey-blur{
  display: none;
  z-index: 0;
  background-color: rgba(1, 1, 1, 0.82);;
  width: 100%;
  position: absolute;
  height: 7500px;
}

#scroll-indicator{
  display: flex;
  align-self: center;
  position: absolute;
  flex-direction: column;
  z-index: 1;
  top: 605px;
}

#arrow {
  position: absolute;
  animation: infinite 4s bobble;
  bottom: 0%;
}

@keyframes bobble {
  0% { transform: translateY(0%)}
  12.5% {transform: translateY(12%)}
  25% {transform: translateY(0%)}
  37.5% {transform: translateY(12%)}
  50% {transform: translateY(0%)}
  62.5% {transform: translateY(0%)}
  75% {transform: translateY(0%)}
  89.5% {transform: translateY(0%)}
  100% { transform: translateY(0%)}
}

.nav-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  position: fixed;
  background-color: #1e212c;
  z-index: 10;
}

#nav-bar-margin {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

#box1-margin {
  display: flex;
  flex-direction: row;
  min-width: 1439px;
  justify-content: space-between;
}

#box2-margin {
  display: flex;
  flex-direction: column;
  min-width: 1439px;
}

#box3-margin {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1439px;
}

#box4-margin {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1439px;
}

#box5-margin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1189px;
}

.introduction-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 103px 187px;
}

.introduction-box2 {
  display: flex;
}

#title-name {
  display: flex;
  height: 100%;
  align-items: center;
  color: #ededf5;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 125px;
  cursor: pointer;
}

.nav-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-button {
  color: #e0e1e8;
  margin-right: 70px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.nav-button:hover {
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

#github-nav {
  margin-right: 125px;
}


#github-nav:hover {
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

.box1 {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  background-color: #171a22;
  width: 100%;
}

#gcc-badge1 {
  width: 115px;
  margin-bottom: 27px;
}

#box1-title1{
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  width: 470px;
  margin-bottom: 20px;
}

#box1-title2 {
  color: #cecfd1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 45px;
}

.contact-me1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #293852;
  color: #309bf6;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  width: 188px;
  height: 48px;
  border-radius: 10px;
}

.contact-me1:hover{
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

.box-title {
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  line-height: 37.5px;
}

.subtext {
  font-family: 'Poppins';
  color: rgba(255, 255, 255, 0.68);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

#keith-profile{
  position: absolute;
  width: 414px;
  z-index: 1;
  margin-top: 63px;
  margin-left: -20px;
}

#profile-backdrop {
  height: 402px;
  width: 430px;
  background-color: #313746;
  margin-top: 91px;
  margin-right: 190px;
}

.box2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  background-color: #1e212c;
  margin-top: -1px;
  padding-bottom: 250px;
}

#box2a-title{
  margin-top: 146px;
  margin-bottom: 50px;
}

#box2b-title{
  margin-bottom: 29px;
  margin-top: 146px;
}

#box2-subtext {
  text-align: center;
  margin-bottom: 130px;
  width: 498px;
  display: flex;
  align-self: center;
}

.about-me {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-me-buttons {
  display: flex;
  flex-direction: row;
  margin-left: 87px;

}

#about-me-box1 {
  display: flex;
  flex-direction: column;
}

#about-me-box2 {
  display: flex;
  flex-direction: column;
  margin-top: 174px;
}

.box2-dec{
  display: flex;
  flex-direction: column;
  height: 380px;
  width: 648px;
  background-color: #262c3b;
}

#box2-title2 {
  font-family: 'Poppins';
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-top: 100px;
  margin-bottom: 29px;
  margin-left: 87px;
}

#subtext-box2 {
  margin-bottom: 71px;
  margin-left: 87px;
}

.resume {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  color: #309bf6;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 188px;
  height: 48px;
  background: #293852;
  border-radius: 10px;
}

.resume:hover{
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

#resume1 {
  margin-right: 36px;
}

#footer-buttons {
  display: flex;
  flex-direction: row;
}

.contact-me2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  width: 188px;
  height: 48px;
  background: #384158;
  border-radius: 10px;
}

.contact-me2:hover{
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

#soccerpic{
  width: 414px;
  height: 535px;
}


#divider {
  background-color: #4a526c;
  width: 1222px;
  height: 2px;
  margin-top: 98px;
  align-self: center;
}

#languages {
  display: flex;
  flex-direction: column;
}

.languages {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

#language-title {
  font-family: 'Poppins';
  font-size: 17px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.68);
  margin-top: 33px;
  line-height: 25px;
}

.language-align{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94px;
  justify-content: center;
}

.box3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #262c3b;
  padding: 142px 0 145px 0;
  margin-top: -1px;
}

.portfolio {
  display: flex;
  width: 935px;
  justify-content: space-between;
  margin: 142px 0 133px 0;
}

.github-bttn {
  width: 317px;
  height: 48px;  
  background: #293852;
  border-radius: 10px;
  font-family: Poppins;
  color: #309BF6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#github-bttn{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #309bf6;
  width: 317px;
  height: 48px;
  background: #293852;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

#github-bttn:hover{
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

.box4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1e212c;
  padding: 146px 0 161px 0;
  margin-top: -2px;
}

.skills {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.skills-box{
  display: flex;
  flex-direction: column;
  text-align: center;
}

#subtext-box4 {
  margin: 29px 0 134px 0;
  text-align: center;
}

#divider2{
  width: 2px;
  height: 216px;
  background-color: #4a526c;
  margin: 0 124px 0 123px;
}

#divider4{
  display: none;
  height: 2px;
  width: 262px;
  background-color: #4a526c;
  margin: 103px 0 85px 0;
}

#box4-text {
  color: rgba(255, 255, 255, 0.68);
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 35px;
}

#gcc-badge2{
  width: 216px;
  height: 216px;
}

.box5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #171a22;
  padding: 93px 0 98px 0;
  margin-top: -2px;
}

.footer-box1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#box5-title {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 31px;
}

.quick-access{
  display: flex;
  flex-direction: row;
}

.footer-bttn {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.68);
  margin-right: 60px;
  text-align: center;
  cursor: pointer;
}

.footer-bttn:hover {
  animation: hoverEffect 0.1s linear;
  filter: brightness(80%);
}

#resume2 {
  margin-right: 36px;
}

#close-bttn {
  display: none;
  color: white;
  font-size: 40px;
  font-weight: 100;
  font-family: 'Kanit', sans-serif;
  transform: scale(1.5, 1.1);
  margin-right: 15px;
  margin-top: -20px;
}

.btn-line {
  width: 36px;
  height: 1px;
  background-color: #fff;
}

#drop-down-btn {
  display: none;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-direction: column;
  margin-top: 23px;
  margin-bottom: 21px;
  margin-right: 22px;
}

#drop-down {
  display: flex;
  right: 0px;
  background-color: #1E212C;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 243px;
  height: 292px;
  margin-top: 70px;
  position: absolute;
  z-index: 1;
}

.header_link {
  font-weight: bold;
  border: none;
  border-bottom: 3px solid transparent;

  color: #e0e1e8;
  margin-right: 62px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.selected {
  color: #309BF6;
}

@keyframes slideInLeft {
  0% {right: -100px; opacity: 0;}
  100%{right: -1px; opacity:1}
}

@keyframes slideOutLeft {
  0% {right: -1px; opacity: 1;}
  100%{right: -100px; opacity:0}
}

@media (max-width: 1256px) {
  #box5-margin {flex-direction: column; align-content: center; align-items: center; text-align: center;}
  #box5-title {margin-bottom: 44px;}
  .quick-access {margin-bottom: 56px;}
  .footer-bttn {margin-right: 74px}

  #title-name {margin: 0 0 0 39px}
  #github-nav {margin: 0 39px 0 0}
}

@media(max-width: 1095px) {
  #box1-margin {min-width: 0;}
  .introduction-box1{text-align: center; align-items: center; margin: 67px 0 109px 0;}
  .introduction-box2 {display: none;}
  #gcc-badge1 {margin-bottom: 37px}
  #box-title1 {margin-bottom: 24px}
  #box-title2 {margin-bottom: 40px;}

  #box2-margin {min-width: 0;}
  .about-me{flex-direction: column;}
  #about-me-box1 {align-items: center;}
  #box2a-title {margin-top: 128px; margin-bottom: 58px;}
  .box2-dec {align-items: center;}
  #box2-title2 {margin: 76px 0 15px 0; text-align: center;}
  #subtext-box2 {margin: 0 0 67px 0; text-align: center;}
  .about-me-buttons {margin: 0;}
  #resume1 {margin-right: 64px;}
  #about-me-box2 {margin: -75px; align-items: center;}
  #soccerpic {width: 414px; height: 535px;}
  #divider {margin-top: 181px; width: 648px}

  #box2 {margin-bottom: 209px}
  #box2b-title {margin-top: 128px; margin-bottom: 28px;}
  #box2-subtext {margin-bottom : 105px;}
  .languages {flex-wrap: wrap; width: 390px; column-gap: 200px; row-gap: 86px; align-self: center; justify-content: space-between;}

  #box3 {padding: 128px 0 180px 0}
  .portfolio {margin: 30px 0 0px 0; flex-direction: column;}
  #portfolio-logo {text-align: center; margin-top: 122px;}
  .github-bttn {width: 418px; height: 48px; margin-top: 111px;}

  .box4 {padding: 128px 0 152px 0}
  .skills {flex-direction: column; align-items: center;}
  #divider2{display: none}
  #divider4{display: flex;}
  #subtext-box4 {margin: 35px 0 112px 0}
  #box4-text {margin-bottom: 85px;}

  .box5 {padding: 62px 0 70px 0}
}

@media (max-width: 1000px) {
  #title-name{margin-left: 40px}
  #drop-down-btn{display: flex;}
  .nav-button-row{display: none;}
  .nav-button {margin: 0 41px 21px 0}
  
  #divider3{display: flex;}
}

@media (max-height: 693px) {
  #scroll-indicator{top: 85%;}
}

@media (orientation: portrait) {
  #scroll-indicator{display: none;}
}

@media (max-width: 635px) {
  .box2-dec {width: 90%;}
  #subtext-box2 {width: 290px; margin-bottom: 46px}

}

@media (max-width: 548px) {
  #box1-title1 {width: auto; padding: 0 20px 0 20px}

  #resume1 {margin-right: 20px}

  #box2-subtext {width: 350px;}
  .languages {column-gap: 30px; width: 300px;}

  .github-bttn {width: 300px;}

  #subtext-box4 {width: 290px}

  .footer-bttn {margin: 0; width: 98px}
  .quick-access {justify-content: center;width: 300px; column-gap: 50px; flex-wrap: wrap; row-gap: 20px;}
}

@media (max-width: 435px) {
  .box2-dec {width: 100%}
  #box2-title2 {width: 200px}
  #soccerpic {width: 290px; height: auto;}
  .resume {width: 140px}
  #resume2 {margin-right: 10px}
  .contact-me2 {width: 140px}
}

@media (max-width: 370px) {
  #box2-subtext {width: 290px}
  .languages {column-gap: 0px;}
  .language-align {width: 80px; margin: 0 20px 0 20px}

  #portfolio-logo {margin-top: 80px;}
  .portfolio-img {width: 250px;}
  .github-bttn {margin-top: 90px}
}
